<script>
const operationsHotelsLink = importVueComp('components/booking', 'BookingOperationsHotelsNavLink', 'custom');
export default {
    name: 'custom-operations-hotel-link',
    mixins: [operationsHotelsLink],
    data () {
        return {
            order: ['PENDING', 'REQUESTED', 'CONFIRMED', 'RE_CONFIRMED', 'CANCELED', 'CANCELED_BY_PAX'],
            colors: {
                'text-danger': ['PENDING', 'CANCELED', 'NOT_SERVICES', 'CANCELED_BY_PAX'],
                'text-success': ['CONFIRMED', 'RE_CONFIRMED'],
                'text-primary': ['REQUESTED']
            }
        }
    },
}
</script>
