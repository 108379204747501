<!-- MATER BookingFinalPreviewDayService-->
<template>
    <div>
        <strong v-if="service.PickupInfo && !canceledByPax">
            <i class="h4 font-weight-bold text-primary">{{ service.PickupInfo }}</i>
        </strong>
        <div v-if="service.Title && description && !canceledByPax" class="mt-2">
            <div class="d-flex" >
                <p class="font-weight-bold font-italic underline mr-3">{{ service.Title }}</p>
                <a 
                    href="#" 
                    @click.prevent="toggleDescription"
                    class="font-italic"
                >
                    {{ showDescription ? tr("See Less") : tr("See More") }}
                </a>
            </div>
            <div v-if="showDescription" class="service-description">
                <text-rows-style :text="description"></text-rows-style>
            </div>
        </div>

        <div v-else-if="description && !canceledByPax">
            <text-rows-style :text="description"></text-rows-style>
        </div>

        <div v-if="service.SupplierId &&
            !service.Supplier.HideDocumentation &&
            firstSupplierDay[service.SupplierId] == service.id &&
            !canceledByPax"
        >
            <div v-if="service.Supplier.Name">
                <b>{{ tr("Local operator") }}</b>: {{ service.Supplier.Name }}
            </div>
            <div v-if="service.Supplier.Phone">
                <b>{{ tr("Phone") }}</b>: {{ service.Supplier.Phone }}
            </div>
        </div>
    </div>
</template>

<script>
    let bookingFinalPreviewService = importVueComp("components/documentation", "BookingFinalPreviewService");
    import { mapState } from "vuex";
    export default {
        name: "booking-final-day-service",
        props: ["day", "langCode", "service"],
        components: {
            "booking-final-preview-service": bookingFinalPreviewService,
        },
        data () {
            return {
                showDescription: false 
            };
        },
        mounted () {
            if (this.service.Title) this.showDescription = false;
        },
        methods: {
            toggleDescription() {
                this.showDescription = !this.showDescription;
            },
            ifText(s) {
                return tools.ifText(s);
            },
            tr(t) {
                return tr(t, this.langCode);
            },
            getHotelName(day) {
                let res = [];
                let ids = {};
                if ( this.hotelsByDay[day.DayNr] && this.hotelsByDay[day.DayNr].BookingDayRooms) {
                    for (let room of this.hotelsByDay[day.DayNr].BookingDayRooms) {
                        if (!room.Hotel) continue;
                        if (ids[room.Hotel.id]) continue;
                        ids[room.Hotel.id] = true;
                        res.push(`${room.Hotel.Name}, ${room.Hotel.Address}`);
                    }
                }
                return res.join("; ");
            },
            cleanDescription(description) {
                if (!description) return "";
                const cleaned = description.replace(/<p>(\s|<br>)*<\/p>/g, "");
                const parser = new DOMParser();
                const doc = parser.parseFromString(cleaned, "text/html");
                return doc.body.textContent.trim();
            },
        },
        computed: {
            ...mapState({
                recordStore: (state) => state.main.record,
            }),
            firstSupplierDay () {
                let res = {};
                for (let day of this.recordStore.BookingDays) {
                    for (let s of day.BookingDayServices) {
                        if (s.Service && s.Service.VoucherDisabled) continue;
                        let supplierId = s.SupplierId;
                        if (s.Service && s.Service.SupplierId) supplierId = s.Service.SupplierId;
                        if (!supplierId) continue;
                        if (res[supplierId] == undefined) res[supplierId] = s.id;
                    }
                }
                return res;
            },
            hotelsByDay() {
                return this.recordStore.hotelsByDay;
            },
            description () {
                if (!this.cleanDescription(this.service.Description)) return ""
                return this.service.Description
            },
            canceledByPax () {
                if (this.service && this.service.Status == "CANCELED_BY_PAX") return true;
            } 
        },
    };
</script>

<style scoped>
    .underline {
        text-decoration: underline;
    }
</style>